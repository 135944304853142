import './KnowledgeBaseArticlePart.scss'

import { Link } from 'gatsby'
import React, { FunctionComponent } from 'react'
import { getLocalizedUrl } from 'Shared/utils/localeURL'

export interface KnowledgeBaseArticlePartType {
  contentType: 'EasySoftwareKnowledgeBaseArticle'
  contentItemId: string
  path: string
  displayText: string
}

const rootClass = `knowledge-base-article-part`

const KnowledgeBaseArticlePart: FunctionComponent<KnowledgeBaseArticlePartType> = (
  props
) => {
  return (
    <div className={`pux-repeater-item ${rootClass}-wrapper`}>
      <Link to={`${getLocalizedUrl(props.path)}`}>{props.displayText}</Link>
    </div>
  )
}

export default KnowledgeBaseArticlePart
